import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  backgroundImage,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`PageHeader relative ${className}`}>
      <div id="About"></div>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <Image className="PageHeader--Title"
        src={title}
        style="height: 12vh; width: 60vh;"
      />
      </div>
        )
}

PageHeader.propTypes = {
  title: PropTypes.string,
}

export default PageHeader
