import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image'
import '../components/PostCard.css'
import '../components/PostSection.css'



// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, featuredImage, homeLogo, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      backgroundImage={featuredImage}
      homeLogo={homeLogo}
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <h2 className="taCenter">
      <section className="section">
        <div className="container">
          <div className="PostSection">
            <div className="PostSection--Grid">
              <div className="PostCard">
                <a href="/machinery/">
                <div className="PostCard--Image relative">
                  <Image background src="../images/Machinery.jpg"/>
                </div>
                <div className="PostCard--Content">
                  <u><h3 className="PostCard--Title">Machinery</h3></u>
                </div>
                </a>
              </div>
              <div className="PostCard">
                <a href="/abnormal/">
                <div className="PostCard--Image relative">
                  <Image background src="../images/Abnormal.jpg"/>
                </div>
                <div className="PostCard--Content">
                  <u><h3 className="PostCard--Title">Abnormal Loads</h3></u>
                </div>
                </a>
              </div>
              <div className="PostCard">
                <a href="/international/">
                <div className="PostCard--Image relative">
                  <Image background src="../images/International.jpg"/>
                </div>
                <div className="PostCard--Content">
                  <u><h3 className="PostCard--Title">International</h3></u>
                </div>
                </a>
              </div>
              <div className="PostCard">
                <a href="/hay/">
                <div className="PostCard--Image relative">
                  <Image background src="../images/Hay.jpg"/>
                </div>
                <div className="PostCard--Content">
                  <u><h3 className="PostCard--Title">Hay & Straw</h3></u>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </h2>
    <br />
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        featuredImage
      }
    }
  }
`
